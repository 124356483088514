import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

const UpdatesPage = ({ data, location }) => {
  const title = 'Updates';
  const dates = {};

  data.matomo.nodes.forEach(function (edge) {
    edge.versions.forEach(function (version) {
      edge.currentVersion = version.name;
      if (version.release) {
        let date = version.release.slice(0, 10);
        if (dates[date] === undefined) {
          dates[date] = [];
        } else {
        }
        dates[date].push({
          name: edge.id,
          version: version.name,
          displayName: `Matomo core`,
          repositoryUrl: `https://github.com/matomo-org/matomo`,
        });
      }
    });
  });

  data.themes.nodes.forEach(function (edge) {
    edge.versions.forEach(function (version) {
      edge.currentVersion = version.name;
      edge.numDownloads = version.numDownloads;
      if (version.release) {
        let date = version.release.slice(0, 10);
        if (dates[date] === undefined) {
          dates[date] = [];
        } else {
        }
        dates[date].push({
          name: edge.id,
          version: version.name,
          displayName: edge.displayName,
          repositoryUrl: edge.repositoryUrl,
          homepage: edge.homepage,
        });
      }
    });
  });

  data.plugins.nodes.forEach(function (edge) {
    edge.versions.forEach(function (version) {
      edge.currentVersion = version.name;
      edge.numDownloads = version.numDownloads;
      if (version.release) {
        let date = version.release.slice(0, 10);
        if (dates[date] === undefined) {
          dates[date] = [];
        } else {
        }
        dates[date].push({
          name: edge.id,
          version: version.name,
          displayName: edge.displayName,
          repositoryUrl: edge.repositoryUrl,
          homepage: edge.homepage,
        });
      }
    });
  });

  const orderedDates = Object.keys(dates)
    .sort()
    .reduce((obj, key) => {
      obj[key] = dates[key];
      return obj;
    }, {});

  const reversedKeys = Object.keys(orderedDates).reverse();

  const datesData = [];
  reversedKeys.forEach((key) => {
    datesData.push({
      date: key,
      data: orderedDates[key],
    });
  });

  return (
    <Layout location={location} title={title}>
      <h1>{title} </h1>
      <>
        {datesData.map((dateData) => (
          <>
            <h2>{dateData.date}</h2>
            {dateData.data.map((versionData) => (
              <>
                <p>
                  {versionData.repositoryUrl ? (
                    <a href={versionData.repositoryUrl}>
                      {versionData.displayName}
                    </a>
                  ) : versionData.homepage ? (
                    <a href={versionData.homepage}>{versionData.displayName}</a>
                  ) : (
                    versionData.displayName
                  )}{' '}
                  {versionData.version}
                </p>
              </>
            ))}
          </>
        ))}
      </>
    </Layout>
  );
};
export const query = graphql`
  {
    matomo: allMatomo {
      nodes {
        id
        versions {
          release
          name
        }
      }
    }
    plugins: allMatomoPlugin {
      nodes {
        id
        displayName
        repositoryUrl
        homepage
        versions {
          release
          name
          numDownloads
        }
      }
    }
    themes: allMatomoTheme {
      nodes {
        id
        displayName
        repositoryUrl
        homepage
        versions {
          release
          name
          numDownloads
        }
      }
    }
  }
`;

export default UpdatesPage;
